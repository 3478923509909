<template>
    <div>
        <!-- Contraseña factura electrónica -->
        <div class="modal fade" id="modal-password" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        Contraseña de la firma digital
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>

                    <div class="modal-body">
                        <form v-on:submit.prevent>
                            <input v-model="password" type="password" class="form-control" autocomplete="factuflex_password" placeholder="Contraseña">
                        </form>
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>

                        <button type="button" class="btn btn-primary" @click="send">
                            Enviar
                        </button>
                    </div>
                </div>
            </div>
        </div>


        <modal v-if="showModal" @close="showModal = false">
            <p class="text-center" slot="body">
                <i class="far fa-check-circle fa-2x text-success"></i> <br>
                Retención enviada con exito
            </p>
        </modal>

        <modal v-if="showError" @close="showError = false">
            <p class="text-center" slot="body">
                <i class="fas fa-exclamation-triangle fa-2x text-danger"></i> <br>
                Error al enviar:
                <br> {{error}}
                <br> <small>{{ error_info}} </small>
            </p>
        </modal>
        <overlay v-if="overlay"></overlay>

        <ValidationObserver ref="observer" v-slot="{invalid}">
            <div class="row">
                <div class="col-md-12">
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-4" style="display: flex;">
                                    <img v-bind:src="punto.logo" alt="" class="img-fluid my-auto">
                                </div>
                                <div class="col-md-8">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="h2 float-right">
                                                {{ numero }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label for="punto_id">Punto de emision</label>
                                                    <select @change="secuencial()" class="form-control col-md-12">
                                                        <option v-for="punto in puntos" v-bind:key="punto.id" v-bind:value="punto.id">
                                                            {{punto.establecimiento.codigo}}-{{punto.codigo}}
                                                        </option>
                                                    </select>
                                                </div>

                                                <div class="form-group col-md-6">
                                                    <label for="">Fecha de emision</label>
                                                    <input v-model="date" type="date" class="form-control col-md-12 float-right">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-4 shadow-sm" id="vendor-card">
                <div class="card-header">
                    Sujeto retenido
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="identificacion" class="col-md-5 col-form-label text-md-right">Identificación (*)</label>

                                <div class="col-md-7">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <input name="identificacion" v-model="vendor.identificacion" type="text" class="form-control" required autocomplete="off" autofocus @change="searchVendor">
                                        <span class="text-danger small" role="alert">
                                            <strong>{{ errors[0] }}</strong>
                                        </span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="id_type_id" class="col-md-5 col-form-label text-md-right">Tipo de Identificación (*)</label>

                                <div class="col-md-7">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <select v-model="vendor.id_type_id" class="form-control" name="" required>
                                            <option v-for="id_type in id_types" v-bind:key="id_type.id" v-bind:value="id_type.id">
                                                {{ id_type.name }}
                                            </option>
                                        </select>
                                        <span class="text-danger small" role="alert">
                                            <strong>{{ errors[0] }}</strong>
                                        </span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="razon_social" class="col-md-5 col-form-label text-md-right">Razón social (*)</label>

                                <div class="col-md-7">
                                    <ValidationProvider rules="required|min:3" v-slot="{ errors }">
                                        <input v-model="vendor.razon_social" type="text" class="form-control" required autocomplete="off">
                                        <span class="text-danger small" role="alert">
                                            <strong>{{ errors[0] }}</strong>
                                        </span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="direccion" class="col-md-5 col-form-label text-md-right">Dirección</label>

                                <div class="col-md-7">
                                    <input v-model="vendor.direccion" id="direccion" type="text" class="form-control" name="direccion" value="" autocomplete="off">
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="email" class="col-md-5 col-form-label text-md-right">Email</label>

                                <div class="col-md-7">
                                    <input v-model="vendor.email" id="email" type="email" class="form-control" name="email" value="" autocomplete="off">
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="telefono" class="col-md-5 col-form-label text-md-right">Teléfono</label>

                                <div class="col-md-7">
                                    <input v-model="vendor.telefono" id="telefono" type="text" class="form-control" name="telefono" value="" autocomplete="off">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="card mt-4 shadow-sm" id="vendor-card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="identificacion" class="col-md-5 col-form-label text-md-right">Tipo de comprobante (*)</label>

                                <div class="col-md-7">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <select v-model="comprobante.comprobante_type_id" class="form-control" name="" required>
                                            <option v-for="comprobante_type in comprobante_types" v-bind:key="comprobante_type.id" v-bind:value="comprobante_type.id">
                                                {{ comprobante_type.nombre }}
                                            </option>
                                        </select>
                                        <span class="text-danger small" role="alert">
                                            <strong>{{ errors[0] }}</strong>
                                        </span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="id_type_id" class="col-md-5 col-form-label text-md-right">Número de comprobante (*)</label>

                                <div class="col-md-7">

                                    <ValidationProvider rules="required|length:15" v-slot="{ errors }">
                                        <input name="numero" v-model="comprobante.numero" type="text" class="form-control" required autocomplete="off">
                                        <span class="text-danger small" role="alert">
                                            <strong>{{ errors[0] }}</strong>
                                        </span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="razon_social" class="col-md-5 col-form-label text-md-right">Fecha de emisión del comprobante (*)</label>

                                <div class="col-md-7">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <input v-model="comprobante.date" type="date" class="form-control" required autocomplete="off">
                                        <span class="text-danger small" role="alert">
                                            <strong>{{ errors[0] }}</strong>
                                        </span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="direccion" class="col-md-5 col-form-label text-md-right">Período fiscal (*)</label>

                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="" class="mb-0">Mes</label>
                                            <input v-model="comprobante.month" type="text" class="form-control" name="direccion" placeholder="05" autocomplete="off">
                                        </div>
                                        <div class="col-md-6">
                                            <label for="" class="mb-0">Año</label>
                                            <input v-model="comprobante.year" type="text" class="form-control" name="direccion" placeholder="2020" autocomplete="off">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div class="card mt-4 shadow-sm" id="impuestos-card">
                <!-- <overlay v-if="overlay"></overlay> -->
                <div class="card-header">
                    Retenciones
                </div>

                <div class="card-body">
                    <ValidationObserver v-slot="{invalid}">
                        <table class="table table-sm" id="detalles">
                            <thead>
                                <tr>
                                    <th>Impuesto (*)</th>
                                    <th>Retención (*)</th>
                                    <th>Base imponible (*)</th>
                                    <th>% de retencion (*)</th>
                                    <th>Valor retenido (*)</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(retencion, index) in retenciones"  v-bind:key="index">
                                    <td style="width: 150px;">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <select @change="searchImpuesto(index)" v-model="retencion.impuesto_type_id" class="form-control">
                                                <option v-for="impuesto_type in impuesto_types" v-bind:key="impuesto_type.id" v-bind:value="impuesto_type.id">
                                                    {{ impuesto_type.nombre }}
                                                </option>
                                            </select>
                                            <span class="text-danger small" role="alert">
                                                <strong>{{ errors[0] }}</strong>
                                            </span>
                                        </ValidationProvider>
                                    </td>

                                    <td style="width: 250px;">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <select @change="setImpuesto(index)" v-model="retencion.impuesto_id" class="form-control">
                                                <option v-for="impuesto in retencion.impuestos" v-bind:key="impuesto.id" v-bind:value="impuesto.id">
                                                    {{ impuesto.nombre }}
                                                </option>
                                            </select>
                                            <span class="text-danger small" role="alert">
                                                <strong>{{ errors[0] }}</strong>
                                            </span>
                                        </ValidationProvider>
                                    </td>

                                    <td>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input v-model="retencion.base_imponible"  type="number" class="form-control" @keyup="update(index)" @change="update(index)">
                                        </div>
                                    </td>

                                    <td>
                                        <div class="input-group">
                                            <div class="input-group-append">
                                                <input v-model="retencion.porcentaje"  type="number" class="form-control" readonly>
                                                <span class="input-group-text">%</span>
                                            </div>

                                        </div>
                                    </td>


                                    <td>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input v-model="retencion.valor_retenido"  type="number" class="form-control" readonly>
                                        </div>
                                    </td>

                                    <td>
                                        <button v-if="retenciones.length > 1" type="button" class="btn btn-sm text-danger" @click="remove(index)">
                                            <i class="fas fa-times"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <button :disabled="invalid" class="btn btn-sm btn-primary float-right" type="button" @click="addRow">
                            <i class="fas fa-plus-circle"></i> Agregar otro
                        </button>
                    </ValidationObserver>
                </div>
            </div>

            <div class="card mt-4" id="impuestos-card">
                <div class="card-footer">
                    <button :disabled="invalid" @click="showPassword" type="button" class="btn btn-primary float-right">
                        Guardar y enviar retención
                    </button>
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import { extend, validate } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/es.json';
import {required} from 'vee-validate/dist/rules';

Object.keys(rules).forEach(rule => {
  extend(rule, {
        ...rules[rule],
        message: messages[rule]
      });
});

extend('required', {
    ...required,
    message: 'El campo es obligatorio'
});

export default {
    props: [
        'vendor_prop',
        'secuencial_prop',
        'fecha_emision'
    ],
    data() {
        return {
            showModal: false,
            showError: false,
            overlay: false,
            punto: [],
            vendor: this.vendor_prop ? JSON.parse(this.vendor_prop) : [],
            id_types : [],
            comprobante_types : [],
            impuesto_types : [],
            retenciones: [
                {
                    base_imponible: 0,
                    porcentaje: 0
                }
            ],
            numero : '001-001-000000001',
            puntos: [],
            date: this.fecha_emision ? this.fecha_emision : new Date().toISOString().substr(0, 10),
            password: '',
            comprobante: {
                month: '05',
                year: '2020',
                date: new Date().toISOString().substr(0, 10),
            }
        }
    },

    mounted() {
        axios
            .get('/app/puntos/list')
            .then(response => {
                this.puntos = response.data.puntos;
                this.punto = this.puntos[0];
                this.secuencial();
                this.overlay = false;
            });

        axios
            .get('/app/id_types/list')
            .then(response => {
                this.id_types = response.data.id_types;
            });

        axios
            .get('/app/impuesto_types/list')
            .then(response => {
                this.impuesto_types = response.data.impuesto_types;
            });

        axios
            .get('/app/comprobante_types/list')
            .then(response => {
                this.comprobante_types = response.data.comprobante_types;
            });
    },

    methods: {

        showPassword() {
            $('#modal-password').modal('show');
        },

        secuencial() {
            this.numero = this.punto.establecimiento.codigo + '-' + this.punto.codigo + '-' + this.punto.secuencial_retenciones.toString().padStart(9, '0');

            if (this.secuencial_prop) {
                this.numero = this.punto.establecimiento.codigo + '-' + this.punto.codigo + '-' + this.secuencial_prop.toString().padStart(9, '0');
            }

        },

        searchVendor() {
            validate(this.vendor.identificacion, 'required').then(result => {
                if (result.valid) {
                    this.overlay = true;
                    axios
                        .post('/app/vendor/identificacion', {
                            identificacion: this.vendor.identificacion
                        })
                        .then(response => {
                            var vendor = response.data.vendor;
                            if (vendor) {
                                this.vendor = vendor;
                            } else {
                                vendor = {
                                    identificacion : this.vendor.identificacion
                                };

                                this.vendor = vendor;
                            }
                            this.overlay = false;
                        });
                }
            });
        },

        searchImpuesto(index) {
            this.overlay = true;
            axios
                .post('/app/impuesto/list', {
                    impuesto_type_id: this.retenciones[index].impuesto_type_id
                })
                .then(response => {
                    var retencion = this.retenciones[index];
                    retencion.impuestos = response.data.impuestos;
                    this.$set(this.retenciones, index, retencion);
                    this.overlay = false;
                });
        },

        setImpuesto(index) {
            var retencion = this.retenciones[index];

            let impuesto = this.retenciones[index].impuestos.find(element => element.id == retencion.impuesto_id);
            retencion.porcentaje = impuesto.porcentaje;
            retencion.valor_retenido = (retencion.porcentaje * retencion.base_imponible / 100).toFixed(2);
        },

        addRow () {
            this.retenciones.push({
                base_imponible: 0,
                valor_retenido: 0
            });
        },

        remove (index) {
            this.retenciones.splice(index, 1);
        },

        update(index) {
            var retencion = this.retenciones[index];
            retencion.valor_retenido = (retencion.porcentaje * retencion.base_imponible / 100).toFixed(2);
            this.$set(this.retenciones, index, retencion);
        },

        check0(e) {
            if (e.target.value == '') {
                e.target.value = 0;
            }
        },

        send() {
            $('#modal-password').modal('hide');
            this.overlay = true;
            axios.
                post('/app/retencion', {
                    vendor: this.vendor,
                    date: this.date,
                    punto: this.punto,
                    retenciones: this.retenciones,
                    password: this.password,
                    comprobante: this.comprobante,
                    secuencial: this.secuencial_prop
                }).then(response => {
                    if (response.data.clave_acceso) {
                        this.vendor = [];
                        this.overlay = false;
                        this.$refs.observer.reset();
                        this.punto.secuencial_retenciones++;
                        this.secuencial();

                        this.retenciones = [
                            {
                                base_imponible: 0,
                                porcentaje: 0
                            }
                        ];

                        this.comprobante = {
                            month: '05',
                            year: '2020',
                            date: new Date().toISOString().substr(0, 10),
                        };

                        this.showModal = true;
                        window.scrollTo(0,0);
                        this.secuencial_prop = null;
                    } else {
                        this.overlay = false;
                        this.showError = true;
                        this.error = response.data.errors.mensaje.mensaje;
                        this.error_info = response.data.errors.mensaje.informacionAdicional;
                    }

                }).catch(error => {
                    this.overlay = false;
                    this.showError = true;
                    this.error = error.response.statusText;
                });
        }
    }
}
</script>
