<template>
    <div>
        <!-- Contraseña factura electrónica -->
        <div class="modal fade" id="modal-password" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        Contraseña de la firma digital
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>

                    <div class="modal-body">
                        <form v-on:submit.prevent>
                            <input v-model="password" type="password" class="form-control" autocomplete="factuflex_password" placeholder="Contraseña">
                        </form>
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>

                        <button type="button" class="btn btn-primary" @click="send">
                            Enviar
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Seller Search Modal -->
        <div class="modal fade" id="modal-seller-search" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            Buscar vendedores
                        </h4>

                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>

                    <div class="modal-body">
                        <input @keyup="searchSellers" v-model="seller_search" type="text" class="form-control" autocomplete="off" placeholder="Buscar vendedor">

                        <table class="table mt-2">
                            <thead>
                                <tr>
                                    <th>RUC</th>
                                    <th>Razon social</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(seller, index) in sellers_search"  v-bind:key="index">
                                    <td>
                                        {{ seller.identificacion }}
                                    </td>
                                    <td>
                                        {{ seller.razon_social }}
                                    </td>
                                    <td>
                                        {{ seller.email }}
                                    </td>
                                    <td>
                                        <button @click="addSeller(index)" class="btn btn-sm btn-success">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Create Client Modal -->
        <div class="modal fade" id="modal-product-search" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            Buscar productos
                        </h4>

                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>

                    <div class="modal-body">
                        <input @keyup="searchProducts" v-model="product_search" type="text" class="form-control" autocomplete="off" placeholder="Buscar producto">

                        <table class="table mt-2">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Descripción</th>
                                    <th>Precio</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(product, index) in products_search"  v-bind:key="index">
                                    <td>
                                        {{ product.codigo }}
                                    </td>
                                    <td>
                                        {{ product.nombre }}
                                    </td>
                                    <td>
                                        $ {{ product.valor }}
                                    </td>
                                    <td>
                                        <button @click="addProduct(index)" class="btn btn-sm btn-success">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Customer Search Modal -->
        <div class="modal fade" id="modal-customer-search" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            Buscar clientes
                        </h4>

                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>

                    <div class="modal-body">
                        <input @keyup="searchCustomers" v-model="customer_search" type="text" class="form-control" autocomplete="off" placeholder="Buscar cliente">

                        <table class="table mt-2">
                            <thead>
                                <tr>
                                    <th>RUC</th>
                                    <th>Razon social</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(customer, index) in customers_search"  v-bind:key="index">
                                    <td>
                                        {{ customer.identificacion }}
                                    </td>
                                    <td>
                                        {{ customer.razon_social }}
                                    </td>
                                    <td>
                                        {{ customer.email }}
                                    </td>
                                    <td>
                                        <button @click="addCustomer(index)" class="btn btn-sm btn-success">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>

        <modal v-if="showModal" @close="showModal = false">
            <p class="text-center" slot="body">
                <i class="far fa-check-circle fa-2x text-success"></i> <br>
                {{exito}}
            </p>
        </modal>

        <modal v-if="showError" @close="showError = false">
            <p class="text-center" slot="body">
                <i class="fas fa-exclamation-triangle fa-2x text-danger"></i> <br>
                Error al enviar:
                <br> {{error}}
                <br> <small>{{ error_info}} </small>
            </p>
        </modal>
        <overlay v-if="overlay"></overlay>

        <ValidationObserver ref="observer" v-slot="{invalid}">
            <div class="row">
                <div class="col-md-12">
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-4" style="display: flex;">
                                    <img v-bind:src="punto.logo" alt="" class="img-fluid my-auto">
                                </div>
                                <div class="col-md-8">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="h2 float-right">
                                                {{ numero }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label for="punto_id">Punto de emision</label>
                                                    <select @change="secuencial()" class="form-control col-md-12">
                                                        <option v-for="punto in puntos" v-bind:key="punto.id" v-bind:value="punto.id">
                                                            {{punto.establecimiento.codigo}}-{{punto.codigo}}
                                                        </option>
                                                    </select>
                                                </div>

                                                <div class="form-group col-md-6">
                                                    <label for="">Fecha de emision</label>
                                                    <input v-model="date" type="date" class="form-control col-md-12 float-right">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-4 shadow-sm" id="customer-card">
                <div class="card-header">
                    Cliente <button type="button" class="btn btn-sm btn-success float-right" @click="showCustomerSearch">
                                <i class="fas fa-search"></i> Buscar clientes
                            </button>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="identificacion" class="col-md-5 col-form-label text-md-right">Identificación (*)</label>

                                <div class="col-md-7">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <input name="identificacion" v-model="customer.identificacion" type="text" class="form-control" required autocomplete="off" autofocus @change="searchCustomer">
                                        <span class="text-danger small" role="alert">
                                            <strong>{{ errors[0] }}</strong>
                                        </span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="id_type_id" class="col-md-5 col-form-label text-md-right">Tipo de Identificación (*)</label>

                                <div class="col-md-7">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <select v-model="customer.id_type_id" class="form-control" name="" @change="checkIdentificacion(customer.id_type_id)">
                                            <option v-for="id_type in id_types" v-bind:key="id_type.id" v-bind:value="id_type.id">
                                                {{ id_type.name }}
                                            </option>
                                        </select>
                                        <span class="text-danger small" role="alert">
                                            <strong>{{ errors[0] }}</strong>
                                        </span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="razon_social" class="col-md-5 col-form-label text-md-right">Razón social (*)</label>

                                <div class="col-md-7">
                                    <ValidationProvider rules="required|min:3" v-slot="{ errors }">
                                        <input v-model="customer.razon_social" type="text" class="form-control" required autocomplete="off">
                                        <span class="text-danger small" role="alert">
                                            <strong>{{ errors[0] }}</strong>
                                        </span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="direccion" class="col-md-5 col-form-label text-md-right">Dirección</label>

                                <div class="col-md-7">
                                    <input v-model="customer.direccion" id="direccion" type="text" class="form-control" name="direccion" value="" autocomplete="off">
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="email" class="col-md-5 col-form-label text-md-right">Email</label>

                                <div class="col-md-7">
                                    <ValidationProvider rules="email" v-slot="{ errors }">
                                        <input v-model="customer.email" id="email" type="email" class="form-control" name="email" value="" autocomplete="off">
                                        <span class="text-danger small" role="alert">
                                                <strong>{{ errors[0] }}</strong>
                                            </span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="telefono" class="col-md-5 col-form-label text-md-right">Teléfono</label>

                                <div class="col-md-7">
                                    <input v-model="customer.telefono" id="telefono" type="text" class="form-control" name="telefono" value="" autocomplete="off">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="card mt-4 shadow-sm" id="products-card">
                <!-- <overlay v-if="overlay"></overlay> -->
                <div class="card-header">
                    Detalle <button type="button" class="btn btn-sm btn-success float-right" @click="showProductSearch">
                        <i class="fas fa-search"></i> Buscar productos
                    </button>
                </div>

                <div class="card-body">
                    <ValidationObserver v-slot="{invalid}">

                        <div class="row d-lg-none" v-for="(product, index) in products"  v-bind:key="index">
                            <div class="col-12">
                                <div class="row mb-1">
                                    <label class="col-5 col-form-label text-right">Código (*)</label>
                                    <div class="col-7">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <input v-model="product.codigo" type="text" class="form-control" @change="searchProduct(index)">
                                            <span class="text-danger small" role="alert">
                                                <strong>{{ errors[0] }}</strong>
                                            </span>
                                        </ValidationProvider>
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <label class="col-5 col-form-label text-right">Cantidad (*)</label>
                                    <div class="col-7">
                                        <input placeholder="Cantidad (*)" v-model="product.cantidad" type="text" class="form-control" @keyup="update(index); calculate()" @change="update(index); calculate()">
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <div class="col-12">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <input placeholder="Descripcion (*)" v-model="product.nombre" type="text" class="form-control">
                                            <span class="text-danger small" role="alert">
                                                <strong>{{ errors[0] }}</strong>
                                            </span>
                                        </ValidationProvider>
                                    </div>
                                </div>

                                <div class="row form-group">
                                    <label class="col-5 col-form-label text-right">Valor (*)</label>
                                    <div class="col-7">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input placeholder="Valor(*)" v-model="product.valor"  type="text" class="form-control" @keyup="update(index); calculate()" @change="update(index); calculate()">
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <label class="col-5 col-form-label text-right">Descuento (*)</label>
                                    <div class="col-7">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input v-model="product.descuento"  type="text" class="form-control" @change="check0(); calculate()" @keyup="calculate()">
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <label class="col-5 col-form-label text-right">Total</label>
                                    <div class="col-7">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">$</span>
                                            </div>
                                            <input v-model="product.total" type="text" class="form-control" readonly>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <label class="col-5 col-form-label text-right">Impuesto (*)</label>
                                    <div class="col-7">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <select @change="calculate()" v-model="product.iva_id" class="form-control">
                                                <option v-for="iva in ivas" v-bind:key="iva.id" v-bind:value="iva.id">
                                                    {{ iva.nombre }}
                                                </option>
                                            </select>
                                            <span class="text-danger small" role="alert">
                                                <strong>{{ errors[0] }}</strong>
                                            </span>
                                        </ValidationProvider>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <button v-if="products.length > 1" type="button" class="btn btn-sm text-danger" @click="remove(index)">
                                                <i class="fas fa-times"></i> Borrar
                                        </button>
                                    </div>
                                </div>

                                <br><br>

                            </div>


                        </div>

                        <div class="table-responsive d-none d-lg-block">
                            <table class="table table-sm" id="detalles">
                                <thead>
                                    <tr>
                                        <th>Código (*)</th>
                                        <th>Cantidad (*)</th>
                                        <th>Descripcion (*)</th>
                                        <th>Valor (*)</th>
                                        <th>Descuento</th>
                                        <th>Subtotal</th>
                                        <th>IVA (*)</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="(product, index) in products"  v-bind:key="index">
                                        <td>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <input v-model="product.codigo" type="text" class="form-control" @change="searchProduct(index)">
                                                <span class="text-danger small" role="alert">
                                                    <strong>{{ errors[0] }}</strong>
                                                </span>
                                            </ValidationProvider>
                                        </td>
                                        <td>
                                            <input v-model="product.cantidad" type="text" class="form-control" @keyup="update(index); calculate()" @change="update(index); calculate()">
                                        </td>
                                        <td>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <input v-model="product.nombre" style="width: 300px;" type="text" class="form-control">
                                                <span class="text-danger small" role="alert">
                                                    <strong>{{ errors[0] }}</strong>
                                                </span>
                                            </ValidationProvider>
                                        </td>
                                        <td>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input v-model="product.valor"  type="text" class="form-control" @keyup="update(index); calculate()" @change="update(index); calculate()">
                                            </div>
                                        </td>
                                        <td>
                                            <input v-model="product.descuento"  type="text" class="form-control" @change="check0(); calculate()" @keyup="calculate()">
                                        </td>
                                        <td>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input v-model="product.total" type="text" class="form-control" readonly>
                                            </div>
                                        </td>
                                        <td>
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <select @change="calculate()" v-model="product.iva_id" style="width: 75px;" class="form-control">
                                                    <option v-for="iva in ivas" v-bind:key="iva.id" v-bind:value="iva.id">
                                                        {{ iva.nombre }}
                                                    </option>
                                                </select>
                                                <span class="text-danger small" role="alert">
                                                    <strong>{{ errors[0] }}</strong>
                                                </span>
                                            </ValidationProvider>
                                        </td>
                                        <td>
                                            <button v-if="products.length > 1" type="button" class="btn btn-sm text-danger" @click="remove(index)">
                                                <i class="fas fa-times"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <button :disabled="invalid" class="btn btn-sm btn-primary float-right" type="button" @click="addRow">
                            <i class="fas fa-plus-circle"></i> Agregar otro
                        </button>
                    </ValidationObserver>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-md-7">
                    <div class="card shadow-sm mb-4">
                        <!-- Card Header - Accordion -->
                        <a href="#collapseCardExample" class="d-block card-header text-dark" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseCardExample">
                            Vendedor
                        </a>
                        <!-- Card Content - Collapse -->
                        <div class="collapse" v-bind:class="{ 'show': seller }" id="collapseCardExample">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <button type="button" class="btn btn-sm btn-success float-right" @click="showSellerSearch">
                                            <i class="fas fa-search"></i> Buscar vendedor
                                        </button>
                                    </div>
                                </div>
                                <div class="row" v-if="seller.razon_social">
                                    <div class="col-md-6">
                                        <p>
                                            <strong>Identificación: </strong> {{ seller.identificacion }}
                                        </p>
                                        <p>
                                            <strong>Nombre: </strong> {{ seller.razon_social }}
                                        </p>
                                        <p>
                                            <strong>Dirección: </strong> {{ seller.direccion }}
                                        </p>
                                    </div>

                                    <div class="col-md-6">
                                        <p>
                                            <strong>Teléfono: </strong> {{ seller.telefono }}
                                        </p>
                                        <p>
                                            <strong>Email: </strong> {{ seller.email }}
                                        </p>
                                        <p>
                                            <strong>Comisión: </strong> {{ seller.comision }} %
                                        </p>

                                        <button @click="seller = []" class="btn btn-sm btn-light text-danger float-right">
                                            Sin vendedor <i class="fas fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card shadow-sm mb-4">
                        <!-- Card Header - Accordion -->
                        <a href="#collapseCardAdicionales" class="d-block card-header text-dark" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseCardAdicionales">
                            Campos adicionales
                        </a>
                        <!-- Card Content - Collapse -->
                        <div class="collapse" v-bind:class="{ 'show': fields.length > 0 }" id="collapseCardAdicionales">
                            <div class="card-body">
                                <div class="row mb-1" v-for="(field, index) in fields"  v-bind:key="index">
                                    <div class="col">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <input v-model="field.name" placeholder="Nombre del campo" type="text" class="form-control">
                                            <span class="text-danger small" role="alert">
                                                <strong>{{ errors[0] }}</strong>
                                            </span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <input v-model="field.value" placeholder="Valor" type="text" class="form-control">
                                            <span class="text-danger small" role="alert">
                                                <strong>{{ errors[0] }}</strong>
                                            </span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-1">
                                        <button type="button" class="btn btn-sm text-danger" @click="removeField(index)">
                                            <i class="fas fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col">
                                        <button :disabled="fields.length >= 3" type="button" class="btn btn-sm btn-primary" @click="addField">
                                            Agregar campo
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-md-5">
                    <div class="card shadow-sm">
                        <div class="card-body">
                            <table class="table">
                                <tr>
                                    <th>Descuento</th>
                                    <td>$ {{ descuento }}</td>
                                </tr>
                                <tr>
                                    <th>Subtotal</th>
                                    <td>$ {{ subtotal }}</td>
                                </tr>
                                <tr>
                                    <th>Subtotal IVA</th>
                                    <td>$ {{ subtotal_iva }}</td>
                                </tr>
                                <tr>
                                    <th>IVA</th>
                                    <td>$ {{ iva }}</td>
                                </tr>
                                <tr>
                                    <th>TOTAL</th>
                                    <td>$ {{ total_importe }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>

                </div>
            </div>

            <div class="card mt-4" id="products-card">
                <div class="card-footer">
                    <button :disabled="invalid" @click="send" type="button" class="btn btn-primary float-right">
                        Guardar
                    </button>
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import { extend, localeChanged, validate } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/es.json';
import {required} from 'vee-validate/dist/rules';

Object.keys(rules).forEach(rule => {
  extend(rule, {
        ...rules[rule],
        message: messages[rule]
      });
});

extend('required', {
    ...required,
    message: 'El campo es obligatorio'
});

export default {
    data() {
        return {
            showModal: false,
            showError: false,
            overlay: false,
            punto: [],
            customer: [],
            seller: [],
            id_types : [],
            ivas: [],
            formas_pago: [],
            fields: [],
            products: [
                {
                    descuento: 0.00,
                    valor: 0.00,
                    total: 0.00,
                    cantidad: 1,
                }
            ],
            pagos: [
                {

                }
            ],
            numero : '001-001-000000001',
            puntos: [],
            date: moment().format('YYYY-MM-DD'),
            subtotal: 0,
            subtotal_iva: 0,
            iva: 0,
            total_importe: 0,
            descuento: 0,
            password: '',
            products_search: [],
            customers_search: [],
            sellers_search: [],
            product_search: '',
            customer_search: '',
            seller_search: '',
            pagada: false,
        }
    },

    mounted() {
        axios
            .get('/app/puntos/list')
            .then(response => {
                this.puntos = response.data.puntos;
                this.punto = this.puntos[0];
                this.secuencial();
                this.overlay = false;
            });

        axios
            .get('/app/id_types/list')
            .then(response => {
                this.id_types = response.data.id_types;
            });

        axios
            .get('/app/ivas/list')
            .then(response => {
                this.ivas = response.data.ivas;
            });
    },

    methods: {
        showProductSearch() {
            $('#modal-product-search').modal('show');
        },

        showCustomerSearch() {
            $('#modal-customer-search').modal('show');
        },

        showSellerSearch() {
            $('#modal-seller-search').modal('show');
        },

        secuencial() {
            this.numero = this.punto.establecimiento.codigo + '-' + this.punto.codigo + '-' + this.punto.secuencial_proformas.toString().padStart(9, '0');
        },

        searchCustomer() {
            validate(this.customer.identificacion, 'required').then(result => {
                if (result.valid) {
                    this.overlay = true;
                    axios
                        .post('/app/customer/identificacion', {
                            identificacion: this.customer.identificacion
                        })
                        .then(response => {
                            var customer = response.data.customer;
                            if (customer) {
                                this.customer = customer;
                                if (this.customer.seller) {
                                    this.seller = this.customer.seller;
                                }
                            } else {
                                customer = {
                                    identificacion : this.customer.identificacion
                                };

                                this.customer = customer;
                            }
                            this.overlay = false;
                        });
                }
            });
        },

        addRow () {
            this.products.push({
                    descuento: 0.00,
                    valor: 0.00,
                    total: 0.00,
                    cantidad: 1,
                });
        },

        remove (index) {
            this.products.splice(index, 1);
            this.calculate();
        },

        addField () {
            this.fields.push({
                    name: '',
                    value: '',
                });
        },

        removeField (index) {
            this.fields.splice(index, 1);
        },

        searchProduct(index) {
            this.overlay = true;
            axios
                .post('/app/product/codigo', {
                    codigo: this.products[index].codigo
                })
                .then(response => {
                    var product = response.data.product;
                    if (product) {
                        product.cantidad = 1;
                        product.total = product.cantidad * product.valor;
                        this.$set(this.products, index, product);
                    } else {
                        product = {
                            codigo : this.products[index].codigo,
                            cantidad: 1,
                            descuento: 0.00,
                            valor: 0.00,
                            total: 0.00,
                        };
                        this.$set(this.products, index, {});
                        this.$set(this.products, index, product);
                    }
                    this.overlay = false;
                })
                .then(() => {
                    this.calculate();
                });
        },

        addProduct(index) {
            var product = this.products_search[index];
            product.cantidad = 1;
            product.total = product.cantidad * product.valor;

            var last = this.products[this.products.length - 1];

            if (last.codigo) {
                this.products.push(product);
            } else {
                this.$set(this.products, this.products.length - 1, product);
            }

            $('#modal-product-search').modal('hide');

            this.calculate();
        },

        searchProducts() {
            if (this.product_search.length > 1) {
                axios
                .post('/app/product/search', {
                    q: this.product_search
                })
                .then(response => {
                    this.products_search = response.data.products;

                });
            }

        },

        addCustomer(index) {
            this.customer = this.customers_search[index];

            if (this.customer.seller) {
                this.seller = this.customer.seller;
            }

            $('#modal-customer-search').modal('hide');
        },

        searchCustomers() {
            if (this.customer_search.length > 1) {
                axios
                .post('/app/customer/search', {
                    q: this.customer_search
                })
                .then(response => {
                    this.customers_search = response.data.customers;
                });
            }

        },

        update(index) {
            var product = this.products[index];
            product.total = product.cantidad * product.valor;
            this.$set(this.products, index, product);
        },

        calculate() {
            this.subtotal = 0;
            this.subtotal_iva = 0;
            this.iva = 0;
            this.descuento = 0;
            this.products.forEach(product => {
                if (product.iva_id != null) {
                    let iva = this.ivas.find(element => element.id == product.iva_id);
                    this.iva += (product.total - product.descuento) * iva.porcentaje / 100;

                    if (iva.porcentaje > 0) {
                        this.subtotal_iva += product.total - product.descuento;
                    }

                    this.descuento += product.descuento * 1;
                }
                this.subtotal += product.total - product.descuento;
                this.total_importe = this.subtotal + this.iva;
            });

            this.subtotal = this.subtotal.toFixed(2);
            this.subtotal_iva = this.subtotal_iva.toFixed(2);
            this.iva = this.iva.toFixed(2);
            this.total_importe = this.total_importe.toFixed(2);
            this.descuento = this.descuento.toFixed(2);

            this.pagos[0].total = this.total_importe;
        },

        check0(e) {
            if (e.target.value == '') {
                e.target.value = 0;
            }
        },

        send() {
            this.overlay = true;
            axios.
                post('/app/proforma', {
                    customer: this.customer,
                    date: this.date,
                    punto: this.punto,
                    products: this.products,
                    seller: this.seller,
                    fields: this.fields,
                    pagos: this.pagos
                }).then(response => {
                    if (response.data.exito) {
                        this.products = [
                            {
                                descuento: 0.00,
                                valor: 0.00,
                                total: 0.00,
                            }
                        ];
                        this.customer = [];
                        this.overlay = false;
                        this.$refs.observer.reset();
                        this.punto.secuencial++;
                        this.secuencial();

                        this.subtotal = 0;
                        this.subtotal_iva = 0;
                        this.iva = 0;
                        this.total_importe = 0;
                        this.showModal = true;
                        this.exito = 'Proforma guardada con éxito';
                        window.scrollTo(0,0);
                    } else {
                        this.overlay = false;
                        this.showError = true;
                        this.error = response.data.errors.mensaje.mensaje;
                        this.error_info = response.data.errors.mensaje.informacionAdicional;
                    }

                }).catch(error => {
                    this.overlay = false;
                    this.showError = true;
                    this.error = error.response.statusText;
                });
        },

        checkIdentificacion(index) {
            if (index === 4) {
                this.customer = {
                    identificacion : 9999999999999,
                    razon_social : 'CONSUMIDOR FINAL',
                    id_type_id : index,
                };
            }
        },

        addSeller(index) {
            this.seller = this.sellers_search[index];

            $('#modal-seller-search').modal('hide');
        },

        searchSellers() {
            if (this.seller_search.length > 1) {
                axios
                .post('/app/seller/search', {
                    q: this.seller_search
                })
                .then(response => {
                    this.sellers_search = response.data.sellers;
                });
            }
        },
    }
}
</script>
