/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('startbootstrap-sb-admin-2/js/sb-admin-2.js');
import 'jquery.easing';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

window.Vue = require('vue');
window.moment = require('moment-timezone');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('overlay', require('./components/common/overlay.vue').default);
Vue.component('modal', require('./components/common/modal.vue').default);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.component('factura-main', require('./components/factura/main.vue').default);
Vue.component('factura-proforma', require('./components/factura/proforma.vue').default);
Vue.component('factura-retry', require('./components/factura/retry.vue').default);
Vue.component('retencion-main', require('./components/retencion/main.vue').default);
Vue.component('proforma-main', require('./components/proforma/main.vue').default);
Vue.component('proforma-edit', require('./components/proforma/edit.vue').default);


Vue.component('nota-credito', require('./components/nota-credito/main.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});


$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$(function () {
    $('[data-toggle="tooltip"]').tooltip();
});
